class Empleado {

  id_empleado = null
  nombre = ''
  apellidos = ''
  username = ''
  email = ''
  password = ''
  role = 'Consultor seo'
  foto = ''
  online_state = false
  acceso = {
    linkbuilding_free : false,
    linkbuilding_paid : false,
    tracking : false
  }
  privilegios = {
    linkbuilding_free : {
      edit: {
        change_estrategia: false,
        empleados: false,
        info: false,
        info_adicional: false,
        edit_link: false,
        acceso_clientes: false
      }
    },
    linkbuilding_paid : {
      edit: {
        change_estrategia: false,
        change_bote: false,
        change_inversion: false,
        empleados: false,
        info_adicional: false,
        edit_link: false,
        acceso_clientes: false
      }
    },
    info_cliente: {
      edit: {
        add_clientes: false,
        info: false,
        info_adicional: false,
        servicios: false,
        tematicas: false
      }
    },
    tracking : {
      edit: {
        add_cliente: false,
        add_keyword: false,
        change_empleados: false,
        edit_clientes: false,
        edit_keywords: false,
        info: false,
        info_adicional: false,
        status_cliente: false,
        status_keyword: false,
        acceso_clientes: false
      },
      view: false
    },
    tasks : {
      edit: {
        delete: false
      }
    },
    empleados : {
      edit: {
        add_empleado : false,
        delete_empleado: false,
        info_adicional: false,
        privilegios: false
      }
    }
  }
  sesion = {
    cliente_seleccionado : '',
    panel : '',
    subpanel : '',
    vista: ''
  }


  constructor(obj){
    if(obj) {
      this.id_empleado = obj.id_empleado ?? null;
      this.nombre = obj.nombre ?? this.nombre;
      this.apellidos = obj.apellidos ?? this.apellidos;
      this.username = obj.username ?? this.username;
      this.email = obj.email ?? this.email;
      this.password = obj.password ?? this.password;
      this.role = obj.role ?? this.role;
      this.foto = obj.foto ?? this.foto;
      this.online_state = obj.online_state ?? this.online_state;
      this.acceso = {
        linkbuilding_free: obj?.acceso?.linkbuilding_free ?? this.acceso.linkbuilding_free,
        linkbuilding_paid: obj?.acceso?.linkbuilding_paid ?? this.acceso.linkbuilding_paid,
        tracking: obj?.acceso?.tracking ?? this.acceso.tracking
      }
      this.privilegios = {
        linkbuilding_free : {
          edit: {
            change_estrategia: obj?.privilegios?.linkbuilding_free?.edit?.change_estrategia ?? this.privilegios.linkbuilding_free.edit.change_estrategia,
            empleados: obj?.privilegios?.linkbuilding_free?.edit?.empleados ?? this.privilegios.linkbuilding_free.edit.empleados,
            info: obj?.privilegios?.linkbuilding_free?.edit?.info ?? this.privilegios.linkbuilding_free.edit.info,
            info_adicional: obj?.privilegios?.linkbuilding_free?.edit?.info_adicional ?? this.privilegios.linkbuilding_free.edit.info_adicional,
            edit_link: obj?.privilegios?.linkbuilding_free?.edit?.edit_link ?? this.privilegios.linkbuilding_free.edit.edit_link,
            acceso_clientes: obj?.privilegios?.linkbuilding_free?.edit?.acceso_clientes ?? this.privilegios.linkbuilding_free.edit.acceso_clientes,
          }
        },
        linkbuilding_paid : {
          edit: {
            change_estrategia: obj?.privilegios?.linkbuilding_paid?.edit?.change_estrategia ?? this.privilegios.linkbuilding_paid.edit.change_estrategia,
            change_bote: obj?.privilegios?.linkbuilding_paid?.edit?.change_bote ?? this.privilegios.linkbuilding_paid.edit.change_bote,
            change_inversion: obj?.privilegios?.linkbuilding_paid?.edit?.change_inversion ?? this.privilegios.linkbuilding_paid.edit.change_inversion,
            empleados: obj?.privilegios?.linkbuilding_paid?.edit?.empleados ?? this.privilegios.linkbuilding_paid.edit.empleados,
            info_adicional: obj?.privilegios?.linkbuilding_paid?.edit?.info_adicional ?? this.privilegios.linkbuilding_paid.edit.info_adicional,
            edit_link: obj?.privilegios?.linkbuilding_paid?.edit?.edit_link ?? this.privilegios.linkbuilding_paid.edit.edit_link,
            acceso_clientes: obj?.privilegios?.linkbuilding_paid?.edit?.acceso_clientes ?? this.privilegios.linkbuilding_paid.edit.acceso_clientes,
          }
        },
        info_cliente: {
          edit: {
            add_clientes: obj?.privilegios?.info_cliente?.edit?.add_clientes ?? this.privilegios.info_cliente.edit.add_clientes,
            info: obj?.privilegios?.info_cliente?.edit?.info ?? this.privilegios.info_cliente.edit.info,
            info_adicional: obj?.privilegios?.info_cliente?.edit?.info_adicional ?? this.privilegios.info_cliente.edit.info_adicional,
            servicios: obj?.privilegios?.info_cliente?.edit?.servicios ?? this.privilegios.info_cliente.edit.servicios,
            tematicas: obj?.privilegios?.info_cliente?.edit?.tematicas ?? this.privilegios.info_cliente.edit.tematicas
          }
        },
        tracking : {
          edit: {
            add_cliente: obj?.privilegios?.tracking?.edit?.add_cliente ?? this.privilegios.tracking.edit.add_cliente,
            add_keyword: obj?.privilegios?.tracking?.edit?.add_keyword ?? this.privilegios.tracking.edit.add_keyword,
            change_empleados: obj?.privilegios?.tracking?.edit?.change_empleados ?? this.privilegios.tracking.edit.change_empleados,
            edit_clientes: obj?.privilegios?.tracking?.edit?.edit_clientes ?? this.privilegios.tracking.edit.edit_clientes,
            edit_keywords: obj?.privilegios?.tracking?.edit?.edit_keywords ?? this.privilegios.tracking.edit.edit_keywords,
            info: obj?.privilegios?.tracking?.edit?.info ?? this.privilegios.tracking.edit.info,
            info_adicional: obj?.privilegios?.tracking?.edit?.info_adicional ?? this.privilegios.tracking.edit.info_adicional,
            status_cliente: obj?.privilegios?.tracking?.edit?.status_cliente ?? this.privilegios.tracking.edit.status_cliente,
            status_keyword: obj?.privilegios?.tracking?.edit?.status_keyword ?? this.privilegios.tracking.edit.status_keyword,
            acceso_clientes: obj?.privilegios?.tracking?.edit?.acceso_clientes ?? this.privilegios.tracking.edit.acceso_clientes
          },
          view: obj?.privilegios?.tracking?.view ?? this.privilegios.tracking.view
        },
        tasks : {
          edit: {
            delete: obj?.privilegios?.tasks?.edit?.delete ?? this.privilegios.tasks.edit.delete
          }
        },
        empleados : {
          edit: {
            add_empleado : obj?.privilegios?.empleados?.edit?.add_empleado ?? this.privilegios.empleados.edit.add_empleado,
            delete_empleado: obj?.privilegios?.empleados?.edit?.delete_empleado ?? this.privilegios.empleados.edit.delete_empleado,
            info_adicional: obj?.privilegios?.empleados?.edit?.info_adicional ?? this.privilegios.empleados.edit.info_adicional,
            privilegios: obj?.privilegios?.empleados?.edit?.privilegios ?? this.privilegios.empleados.edit.privilegios
          }
        }
      }
      this.sesion = {
        cliente_seleccionado : obj?.sesion?.cliente_seleccionado ?? this.sesion.cliente_seleccionado,
        panel : obj?.sesion?.panel ?? this.sesion.panel,
        subpanel : obj?.sesion?.subpanel ?? this.sesion.subpanel,
        vista: obj?.sesion?.vista ?? this.sesion.vista
      }
    }

  }
}

export default Empleado