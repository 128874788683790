export const privilegios = {
  info_cliente: {
    title: 'Información Cliente',
    edit: {
      add_clientes: 'Añadir clientes',
      info: 'Editar información',
      info_adicional: 'Editar información adicional',
      servicios: 'Editar servicios',
      tematicas: 'Editar tematicas'
    }
  },
  linkbuilding_free: {
    title: 'Linkbuilding Free',
    edit: {
      change_estrategia: 'Cambiar estrategia',
      empleados: 'Cambiar empleados',
      info: 'Editar información',
      info_adicional: 'Editar información adicional',
      edit_link: 'Editar enlaces hechos',
      acceso_clientes: 'Permitir asignación clientes'
    }
  },
  linkbuilding_paid: {
    title: 'Linkbuilding Paid',
    edit: {
      change_estrategia: 'Cambiar estrategia',
      change_bote: 'Cambiar bote',
      change_inversion: 'Cambiar inversión',
      empleados: 'Cambiar empleados',
      info_adicional: 'Editar información adicional',
      edit_link: 'Editar enlaces hechos',
      acceso_clientes: 'Permitir asignación clientes'
    }
  },
  tracking: {
    title: 'Tracking',
    edit: {
      add_cliente: 'Añadir cliente',
      add_keyword: 'Añadir keyword',
      change_empleados: 'Cambiar empleados',
      edit_clientes: 'Editar clientes',
      edit_keywords: 'Editar keywords',
      info: 'Editar información',
      info_adicional: 'Editar información adicional',
      status_cliente: 'Editar estado cliente',
      status_keyword: 'Editar estado keyword',
      acceso_clientes: 'Permitir asignación clientes'
    },
    view: ''
  },
  empleados : {
    title: 'Empleados',
    edit: {
      add_empleado : 'Añadir empleados',
      delete_empleado: 'Eliminar empleados',
      info_adicional: 'Editar información',
      privilegios: 'Editar privilegios'
    }
  },
  tasks: {
    title: 'Tareas',
    edit: {
      delete: 'Eliminar tareas'
    }
  }
}