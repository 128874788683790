import React, {Component} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction";
import firebase from '../../../../firebase/Firebase';
import moment from 'moment'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setEditTask, setTareaSeleccionada } from '../../../../redux/actions';
import EditTask from '../../../Clientes/Paneles/PanelTareas/Paneles/EditTask'
import $ from 'jquery'

import './main.scss' // webpack must be configured to do this

const dbCloud = firebase.firestore();

class PanelCalendario extends Component {

  constructor(props){
    super(props)
    this.state={
      fecha: this.props.fecha,
      events:[],
      tareasMensuales:{},
      tareaSeleccionada:null
    }
  }

  componentWillMount = () => {
    this.getTareas()
  }

  componentWillReceiveProps = (newProps) => {
    if(this.state.fecha!==newProps.fecha){
      this.setState({fecha:newProps.fecha}, ()=>{
        this.getTareas()
      })
    }
  }

  getTareas = () => {
    var taskRef = dbCloud.collection('Servicios/Tareas/tareas');
    
    //taskRef = taskRef.where(`empleados.${this.props.empleado.id_empleado}`, '==', true)
    taskRef = taskRef.where(`empleadosArray`, 'array-contains', this.props.empleado.id_empleado)
    taskRef = taskRef.where(`fecha_limite`, '>=', `${this.state.fecha}-01`)
    taskRef = taskRef.where(`fecha_limite`, '<=', `${this.state.fecha}-31`)

    taskRef.onSnapshot(snapshot => {
      var tareas = [], tareasMensuales={}
      snapshot.forEach(doc => {
        
        tareasMensuales[doc.id] = doc.data()
        var a = moment();
        var b = moment(doc.data().fecha_limite)
        tareasMensuales[doc.id].diferencia_dias = b.diff(a, 'days')  

        tareas.push({
          ...doc.data(),
          id:doc.id,
          date:doc.data().fecha_limite,
          diferencia_dias: b.diff(a, 'days')
        })

      });
      this.setState({events: tareas, tareasMensuales})
    })
  }

  viewEvent = (info)=>{

    let container = document.createElement("div");
    //container.className = "conatiner-event-calendar"
    let statusColor = ''
    if(info.event.extendedProps.completado){
      statusColor='green'
    }else if(info.event.extendedProps.estado==="en_proceso"){
      statusColor='orange'
    }else if(info.event.extendedProps.estado==="no_completado"){
      statusColor='gray'
    }

    if(info.event.extendedProps.diferencia_dias<0 && info.event.extendedProps.estado!=="completado"){
      statusColor='red'
    }
    
    container.innerHTML= `
      <div class="conatiner-event-calendar" id=${info.event.id}>
        <div class="status-event-calendar calendar-status-${statusColor}"></div>
        <div class="content-event-calendar">
          ${info.event.title}
        </div>
      <div>
    `

    /*let status = document.createElement("div")
    status.className = "status-event-calendar"
 
    let content = document.createElement("div")
    content.className = "content-event-calendar"
    content.innerHTML = "holaa adjfn akdfnad flkad nfkbdfs"

    container.appendChild(status)
    container.appendChild(content)*/

    return container
  }

  clickEvent = (info) => {
    this.setState({tareaSeleccionada: info.event.id})
    this.props.setEditTask(true)

    document.querySelectorAll('.conatiner-event-calendar')
    $('.conatiner-event-calendar').removeClass('select-event-calendar')
    $('.conatiner-event-calendar#'+info.event.id).addClass('select-event-calendar')
  }

  render(){    
    return(
      <div id='container-clientes-task' className='container-table min-panel-medios-free pdd_0 overflow_v_hidden' ref={scroller => { this.scroller = scroller }} onScroll={this.handleScroll}>
        <div id='container-clientes-task-list'>
          <FullCalendar 
            defaultView="dayGridMonth" 
            plugins={[ dayGridPlugin, interactionPlugin ]}
            eventClick={this.handleDateClick}
            //defaultDate={`${this.state.fecha}-01`}
            validRange= {{
              start: `${this.state.fecha}-01`,
              end: `${this.state.fecha}-31`,
            }}
            events={this.state.events}
            eventRender={(info)=>this.viewEvent(info)}
            locale='es'
            eventClick={(info)=>this.clickEvent(info)}
            aspectRatio={2}
            handleWindowResize={true}
            height={"parent"}
          />
        </div>

        {this.props.editTask && this.state.tareasMensuales[this.state.tareaSeleccionada]  ?
          <EditTask mode='edit' tarea={this.state.tareasMensuales[this.state.tareaSeleccionada]} />: null
        }

      </div>
    )
  }

}


function mapStateToProps(state) {
  return {
    empleado: state.empleado,
    fecha: state.panelEmpleado.fechaCalendario,
    editTask: state.clients.task.editTask,
  }
}
function matchDispatchToProps(dispatch) { return bindActionCreators({ setEditTask, setTareaSeleccionada }, dispatch) }
export default connect(mapStateToProps, matchDispatchToProps)(PanelCalendario);