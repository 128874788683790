import React, { useState } from 'react';
import { connect } from 'react-redux';
import SimpleInputDesplegable from '../../../Global/SimpleInputDesplegable';
import SimpleInput from '../../../Global/SimpleInput';
import styled from 'styled-components';
import firebase from '../../../../firebase/Firebase';
import * as functions from '../../../Global/functions'
import moment from 'moment'
import XLSX from 'xlsx';

const DownloadType = ['Linkbuilding Free', 'Linkbuilding Paid']
const db = firebase.database().ref();

const DownloadLinks = ({ panel_clientes, cliente_seleccionado, clientes, medios_paid, medios_free, close }) => {
  
  const [type, setType] = useState(DownloadType[0])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')


  const downloadLinksXLS = async () => {

    let wb = XLSX.utils.book_new();
    let sheetLbFree, sheetLbPaid, sheetTracking

    if (type === DownloadType[0]) {
      const { lbFreeLinks } = await getLbFreeLinks()
      sheetLbFree = XLSX.utils.json_to_sheet(lbFreeLinks);
      XLSX.utils.book_append_sheet(wb, sheetLbFree, "LinkBuilding Free");
    } else if (type === DownloadType[1]) {
      const { lbPaidLinks } = await getLbPaidLinks()
      sheetLbPaid = XLSX.utils.json_to_sheet(lbPaidLinks);
      XLSX.utils.book_append_sheet(wb, sheetLbPaid, "LinkBuilding Paid");
    } /* else if (type === DownloadType[2]) {
      const { trackingLinks } = await getTrackingLinks()
      sheetTracking = XLSX.utils.json_to_sheet(trackingLinks);
      XLSX.utils.book_append_sheet(wb, sheetTracking, "Tracking");
    } */
    XLSX.writeFile(wb, `Estadisticas.xlsx`);
  }

  const getLbFreeLinks = () => {
    return new Promise(async (resolve, reject) => {
      let lbFreeLinks = []
      let start = moment(startDate)
      let end = moment(endDate)
      
      if (panel_clientes === 'lista') {
        db.child('Servicios/Linkbuilding/Free/Enlaces/clientes').once("value", snapshot => {
          snapshot.forEach(data => {
            Object.entries(data.val().mensualidades).forEach(([mes, links]) => {
              let date = moment(`${mes}-01`)
              if(date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
                Object.entries(links.enlaces).forEach(([idEnlace, enlaceData]) => {
                  if(enlaceData?.id_medio){
                    let enlace = {
                      'Medio': enlaceData.id_medio ? medios_free[enlaceData.categoria]?.medios[enlaceData.id_medio]?.web : '',
                      'Enlace': enlaceData?.enlace ? enlaceData.enlace : '',
                      'Anchor': enlaceData?.anchor ? enlaceData?.anchor : '',
                      'Destino': enlaceData?.destino ? enlaceData?.destino : '',
                      'Tipo': enlaceData?.tipo ? enlaceData?.tipo : '',
                      'Fecha Finalizado': enlaceData?.fecha_fin ? moment(enlaceData?.fecha_fin).format('DD-MM-YYYY') : ''
                    }
                    lbFreeLinks.push({...enlace})
                  }
                })
              }
            })
          });
          return resolve({lbFreeLinks})
        })
      } else {
        db.child(`Servicios/Linkbuilding/Free/Enlaces/clientes/${cliente_seleccionado.id_cliente}/mensualidades`).once("value", snapshot => {
          snapshot.forEach(data => {
            let date = moment(`${data.key}-01`)
            if(date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
              Object.entries(data.val().enlaces).forEach(([idEnlace, enlaceData]) => {
                if(enlaceData?.id_medio){
                  let enlace = {
                    'Medio': enlaceData.id_medio ? medios_free[enlaceData.categoria]?.medios[enlaceData.id_medio]?.web : '',
                    'Enlace': enlaceData?.enlace ? enlaceData.enlace : '',
                    'Anchor': enlaceData?.anchor ? enlaceData?.anchor : '',
                    'Destino': enlaceData?.destino ? enlaceData?.destino : '',
                    'Tipo': enlaceData?.tipo ? enlaceData?.tipo : '',
                    'Fecha Finalizado': enlaceData?.fecha_fin ? moment(enlaceData?.fecha_fin).format('DD-MM-YYYY') : ''
                  }
                  lbFreeLinks.push({...enlace})
                }
              })
            }
          })
          return resolve({lbFreeLinks})
        })
      }
    })
  }

  const getLbPaidLinks = () => {
    return new Promise(async (resolve, reject) => {
      let lbPaidLinks = []
      let start = moment(startDate)
      let end = moment(endDate)
      
      if (panel_clientes === 'lista') {
        db.child('Servicios/Linkbuilding/Paid/Enlaces/clientes').once("value", snapshot => {
          snapshot.forEach(data => {
            let idCliente = data.key
            Object.entries(data.val().mensualidades).forEach(([mes, links]) => {
              let date = moment(`${mes}-01`)
              if(date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
                Object.entries(links.enlaces).forEach(([idEnlace, enlaceData]) => {
                  if(enlaceData?.id_medio){ 
                    let enlace = {
                      'Medio': enlaceData.id_medio ? medios_paid[enlaceData.id_medio]?.web : '',
                      'Destino': enlaceData?.destino ? enlaceData?.destino : '',
                      'Anchor': enlaceData?.anchor ? enlaceData?.anchor : '',
                      'Enlace': enlaceData?.enlace ? enlaceData.enlace : '',
                      'Tipo': enlaceData?.tipo ? enlaceData?.tipo : '',
                      'Fecha Finalizado': enlaceData?.fecha_fin ? moment(enlaceData?.fecha_fin).format('DD-MM-YYYY') : '',
                      'Compra': enlaceData?.precio ? functions.getDecimcals(enlaceData.precio) + ' €' : ''
                    }
                    let beneficio, beneficioCliente = clientes[idCliente].servicios.linkbuilding.paid.home.mensualidades[mes]?.beneficio ? clientes[idCliente].servicios.linkbuilding.paid.home.mensualidades[mes].beneficio : 0,
                    precioVentaEnlace = enlaceData.precio ? functions.calcularPrecioVenta(enlaceData.precio, beneficioCliente) : '-'
  
                    enlace.Venta = enlaceData.fixed_price ? functions.getDecimcals(enlaceData.fixed_price) + ' €' : precioVentaEnlace !== '-' ? functions.getDecimcals(precioVentaEnlace) + ' €' : '-'
  
                    if (enlaceData.fixed_price && enlaceData.precio_fijo) {
                      beneficio = enlaceData.precio ? (+enlaceData.fixed_price) - (+enlaceData.precio) : ''
                    } else if (enlaceData.fixed_price) {
                      beneficio = enlaceData.precio ? ((+enlaceData.fixed_price) - precioVentaEnlace) + (precioVentaEnlace - (+enlaceData.precio)) : ''
                    } else {
                      beneficio = enlaceData.precio ? precioVentaEnlace - (+enlaceData.precio) : ''
                    }
                    enlace.Beneficio = beneficio !== '0' ? functions.getDecimcals(beneficio) + ' €' : beneficio
  
                    lbPaidLinks.push({...enlace})
                  }
                })
              }
            })
          });
          return resolve({lbPaidLinks})
        })
      } else {
        db.child(`Servicios/Linkbuilding/Paid/Enlaces/clientes/${cliente_seleccionado.id_cliente}/mensualidades`).once("value", snapshot => {
          snapshot.forEach(data => {
            let date = moment(`${data.key}-01`)
            if(date.isSameOrAfter(start) && date.isSameOrBefore(end)) {
              Object.entries(data.val().enlaces).forEach(([idEnlace, enlaceData]) => {
                if(enlaceData?.id_medio) {
                  let enlace = {
                    'Medio': enlaceData.id_medio ? medios_paid[enlaceData.id_medio]?.web : '',
                    'Destino': enlaceData?.destino ? enlaceData?.destino : '',
                    'Anchor': enlaceData?.anchor ? enlaceData?.anchor : '',
                    'Enlace': enlaceData?.enlace ? enlaceData.enlace : '',
                    'Tipo': enlaceData?.tipo ? enlaceData?.tipo : '',
                    'Fecha Finalizado': enlaceData?.fecha_fin ? moment(enlaceData?.fecha_fin).format('DD-MM-YYYY') : '',
                    'Compra': enlaceData?.precio ? functions.getDecimcals(enlaceData.precio) + ' €' : ''
                  }
  
                  let beneficio, beneficioCliente = cliente_seleccionado.servicios.linkbuilding.paid.home.mensualidades[data.key]?.beneficio ? cliente_seleccionado.servicios.linkbuilding.paid.home.mensualidades[data.key].beneficio : 0,
                  precioVentaEnlace = enlaceData.precio ? functions.calcularPrecioVenta(enlaceData.precio, beneficioCliente) : '-'
  
                  enlace.Venta = enlaceData.fixed_price ? functions.getDecimcals(enlaceData.fixed_price) + ' €' : precioVentaEnlace !== '-' ? functions.getDecimcals(precioVentaEnlace) + ' €' : '-'
  
                  if (enlaceData.fixed_price && enlaceData.precio_fijo) {
                    beneficio = enlaceData.precio ? (+enlaceData.fixed_price) - (+enlaceData.precio) : ''
                  } else if (enlaceData.fixed_price) {
                    beneficio = enlaceData.precio ? ((+enlaceData.fixed_price) - precioVentaEnlace) + (precioVentaEnlace - (+enlaceData.precio)) : ''
                  } else {
                    beneficio = enlaceData.precio ? precioVentaEnlace - (+enlaceData.precio) : ''
                  }
                  enlace.Beneficio = beneficio !== '0' ? functions.getDecimcals(beneficio) + ' €' : beneficio
  
                  lbPaidLinks.push({...enlace})
                }
              })
            }
          })
          return resolve({lbPaidLinks})
        })
      }
    })
  }

  const getTrackingLinks = () => {
    return new Promise(async (resolve, reject) => {
      let  trackingLinks = []
      let start = moment(startDate)
      let end = moment(endDate)
      
      if (panel_clientes === 'lista') {
        db.child('Servicios/Tracking/Resultados/clientes').once("value", snapshot => {
          snapshot.forEach(data => {
            Object.entries(data.val()).forEach((value, index) => {
              Object.entries(value).forEach(([id, tracking]) => {
                if (tracking.dates){
                  Object.entries(tracking.dates).forEach(([trackingDate, trackingData]) => {
                    let date = moment(trackingDate)
                    if(date.isSameOrAfter(start) && date.isSameOrBefore(end)){
                      let tracking = {
                        'Keyword': trackingData?.keyword ? trackingData?.keyword : '',
                        'Primera Url': trackingData?.results?.first_url ? trackingData?.results?.first_url : ''
                      }
                      trackingLinks.push({...tracking})
                    }
                  })
                }
              })
            })
          });
          return resolve({trackingLinks})
        })
      } else {
        db.child(`Servicios/Tracking/Resultados/clientes/${cliente_seleccionado.id_cliente}`).once("value", snapshot => {
          snapshot.forEach(data => {
            if (data.val().dates) {
              Object.entries(data.val().dates).forEach(([trackingDate, trackingData]) => {
                let date = moment(trackingDate)
                if(date.isSameOrAfter(start) && date.isSameOrBefore(end)){
                  let tracking = {
                    'Keyword': trackingData?.keyword ? trackingData?.keyword : '',
                    'Primera Url': trackingData?.results?.first_url ? trackingData?.results?.first_url : ''
                  }
                  trackingLinks.push({...tracking})
                }
              })
            }  
          })
          return resolve({trackingLinks})
        })
      }
    })
  }

  return (
    <ContainerPopup>
      <div className='opciones-search-popup opciones-search-show-popup pop-up-enlaces-nuevos pop-up-medios-paid-nuevos'>
      <ContainerInputsButtons>
          <div className="title-pop-up title-center-pop-up">Descarga de Enlaces</div>
          <SimpleInputDesplegable lista={DownloadType} title={'Tipo'} text={type} changeValor={(typeSelected) => setType(typeSelected)} />
          <ContainerDate>
            <SimpleInput type={'date'} _class_container={startDate.trim() === '' ? 'error-form-input' : null} title='Fecha Inicio' text={startDate} changeValue={date => { setStartDate(date)}}/>
            <SimpleInput type={'date'} _class_container={endDate.trim() === '' ? 'error-form-input' : null} title='Fecha Fin' text={endDate} changeValue={date => { setEndDate(date)}}/>
          </ContainerDate>
          <div className='btns-finalizar-add-medios-paid'>
            <div className="btn-cancelar-confirm" onClick={(e) => { e.stopPropagation(); close(); }}>Cancelar</div>
            <div className="btn-aceptar-confirm" onClick={downloadLinksXLS}>Descargar</div>
          </div>
      </ContainerInputsButtons> 
      </div>
    </ContainerPopup>
  )
}

function mapStateToProps(state) { return { 
  panel_clientes: state.panel_clientes,
  cliente_seleccionado: state.cliente_seleccionado,
  clientes: state.clientes,
  medios_paid: state.linkbuilding.medios.tipos.paid.medios,
  medios_free: state.linkbuilding.medios.tipos.free.medios
}}
export default connect(mapStateToProps)(DownloadLinks);

const ContainerPopup = styled.div`
  position: absolute;
  top: 40px;
  z-index: 3;
  background: white;
  left: -340px;
`


const ContainerInputsButtons = styled.div`
  height: 320px!important;
  overflow: scroll;
  overflow-x: hidden;
  max-width: 455px!important;
`

const ContainerDate = styled.div`
  display: flex;
`