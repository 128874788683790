import React, { useState, useEffect } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dotProp from 'dot-prop-immutable';
import _ from 'underscore';

import { privilegios } from '../../../Global/Data/privilegios';
import UpdateStateInputs from '../../../Global/UpdateStateInputs';
import CheckBox from '../../../Global/CheckBox';
import { setPopUpInfo } from '../../../../redux/actions'
import firebase from '../../../../firebase/Firebase';

const db = firebase.database().ref();

const PrivilegiosEmpleado = ({ empleadoSeleccionado, empleado, setPopUpInfo }) => {

  const [privilegiosEmpleado, setPrivilegiosEmpleado] = useState(empleadoSeleccionado.privilegios)
  const [edited, setEdited] = useState(false)

  useEffect(() => {
    if(!_.isEqual(privilegiosEmpleado, empleadoSeleccionado.privilegios)){
      setEdited(true)
    } else {
      setEdited(false)
    }
  }, [privilegiosEmpleado])

  const saveData = () => {
    let multiPath = {}
    let accesoEmpleado = {
      linkbuilding_free: false,
      linkbuilding_paid: false,
      tracking: false
    }

    if(privilegiosEmpleado?.linkbuilding_free?.edit?.acceso_clientes) { accesoEmpleado.linkbuilding_free = true }
    if(privilegiosEmpleado?.linkbuilding_paid?.edit?.acceso_clientes) { accesoEmpleado.linkbuilding_paid = true }
    if(privilegiosEmpleado?.tracking?.edit?.acceso_clientes) { accesoEmpleado.tracking = true  }

    let newEmpleado = { ...empleadoSeleccionado, acceso: accesoEmpleado, privilegios: privilegiosEmpleado}
    delete newEmpleado.tareas
    multiPath[`Empleados/${empleadoSeleccionado.id_empleado}`] = newEmpleado
    
    db.update(multiPath)
      .then(() => {
        setPopUpInfo({ visibility: true, status: 'done', moment: Date.now(), text: 'Se han guardado los cambios correctamente' })
      })
      .catch(error => { 
        setPopUpInfo({ visibility: true, status: 'close', moment: Date.now(), text: 'Error al guardar' })
      })

    setEdited(false);
  }

  const undoData = () => {
    setPrivilegiosEmpleado(empleadoSeleccionado.privilegios)
  }

  const changeValue = (checked, privilegio, tipoPrivilegio ) => {
    if(empleado.privilegios.empleados.edit.privilegios){
      setPrivilegiosEmpleado(dotProp.set(privilegiosEmpleado, `${privilegio}.edit.${tipoPrivilegio}`, checked))
    }
  }

  const checkPrivilegios = (privilegios) => {
    if(privilegios){ 
      return Object.entries(privilegios?.edit).some((privilegio) => privilegio[1] === false) 
    }
  }
  
  const selectAllPrivilegios = (checked, privilegio, tiposPrivilegio) => {
    let newPrivilegios = privilegiosEmpleado;
    if(empleado.privilegios.empleados.edit.privilegios){
      Object.entries(tiposPrivilegio.edit).forEach(([keyTipo, tipo]) => {
        if(checked){
          newPrivilegios = dotProp.set(newPrivilegios, `${privilegio}.edit.${keyTipo}`, true)
        } else {
          newPrivilegios = dotProp.set(newPrivilegios, `${privilegio}.edit.${keyTipo}`, false)
        } 
      })
      
      setPrivilegiosEmpleado(newPrivilegios)
    }
  }

  return (
    <div className='sub-container-privilegios'>
      {edited ? <UpdateStateInputs saveData={() => saveData()} undoData={() => undoData()} /> : null}

      <p className='title-informacion-alumno'>2. Privilegios</p>

      {Object.entries(privilegios).map(([keyPrivilegio, privilegio], indice) => {
        return (
          <div className='container-privilegios' key={indice}>
            <div className='parte-superior'>
              <div className='ei-nombre'>
              {privilegio.title}
              </div>
            </div>
            <div>
              <div className='parte-inferior'>
                <CheckBox 
                  text={'Seleccionar todos'}
                  checked={!checkPrivilegios(privilegiosEmpleado?.[`${keyPrivilegio}`])}
                  changeValue={(checked) => selectAllPrivilegios(checked, keyPrivilegio, privilegiosEmpleado?.[`${keyPrivilegio}`])}
                />
                {Object.entries(privilegio.edit).map(([keyTipo, tipo], indice) => {
                  return (
                    <CheckBox
                      key={indice} 
                      text={tipo} 
                      checked={privilegiosEmpleado?.[`${keyPrivilegio}`]?.edit?.[`${keyTipo}`]}
                      changeValue={(checked) => {changeValue(checked, keyPrivilegio, keyTipo)}}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )})
      }
    </div>
  )
}

function mapStateToProps(state) { 
  return {
    empleadoSeleccionado: state.empleado_seleccionado,
    empleado: state.empleado
  }
}
function matchDispatchToProps(dispatch) { return bindActionCreators({ setPopUpInfo }, dispatch) }

export default connect(mapStateToProps, matchDispatchToProps)(PrivilegiosEmpleado);
