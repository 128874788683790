import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMoment } from 'moment';

import SimpleInput from '../../../Global/SimpleInput';
import InformacionEmpleado from './InformacionEmpleado';
import PrivilegiosEmpleado from './PrivilegiosEmpleado';

const PanelInformacion = ({ empleado }) => {

  return (
    <div className='container-table'>
      <div className='container-empleados-informacion'>
        <InformacionEmpleado /> 
        <PrivilegiosEmpleado />
      </div>
    </div>
  )
}

function mapStateToProps(state) { return {} }
function matchDispatchToProps(dispatch) {}
export default connect(mapStateToProps, null)(PanelInformacion);
