import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMoment } from 'moment';

import SimpleInput from '../../../Global/SimpleInput';

const InformacionEmpleado = ({ empleado }) => {

  const saveData = () => {

  }

  const undoData = () => {

  }

  return (
    <div className='sub-container-informacion'>

      <p className='title-informacion-alumno'>1. Información del empleado</p>

      <div className='col-2-input'>
        <SimpleInput title='Nombre del empleado' text={empleado?.nombre +' ' + empleado?.apellidos} type={'block'}/>
        <SimpleInput title='Email del empleado' text={empleado?.email} type={'block'}/>
      </div>
      <div className='col-2-input'>
        <SimpleInput title='Role del empleado' text={empleado?.role} type={'block'}/>
        {/* <SimpleInput title='Contraseña del empleado' text={empleado?.password} type={'block'}/> */}
      </div>
    </div>
  )
}

function mapStateToProps(state) { return {empleado: state.empleado_seleccionado} }
function matchDispatchToProps(dispatch) {}
export default connect(mapStateToProps, null)(InformacionEmpleado);
