import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setEditTask } from '../../../../redux/actions';
import ItemTarea from '../../../Clientes/Paneles/PanelTareas/Paneles/ItemTarea'
import EditTask from '../../../Clientes/Paneles/PanelTareas/Paneles/EditTask'
import moment from 'moment'
import firebase from '../../../../firebase/Firebase';
import * as functions from '../../../Global/functions'
import UpdateStateInputs from '../../../Global/UpdateStateInputs'
import _ from 'underscore'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { ExpansionPanelDetails } from '@material-ui/core';

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import ItemPlantilla from './ItemPlantilla';

const db = firebase.firestore();


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Plantillas = ({editTask, tarea_seleccionada, empleado, actions}) => {

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [plantillas, setPlantillas] = useState({statePlantillas: {}, propsPlantillas: {}})
  const [edited, setEdited] = useState(false)

  const handleChange = idPlantilla => (event, isExpanded) => {
    setExpanded(isExpanded ? idPlantilla : false);
    if(isExpanded) getTaskPlantilla(idPlantilla)

  };

  useEffect(()=>{
    getPlantillas()
  },[])
  

  const getPlantillas = () => {
    var plantillasRef = db.collection('Servicios/Plantillas/plantillas');
    plantillasRef = plantillasRef.where(`status`, "==" , "activo")
    plantillasRef.onSnapshot(snapshot => {
      var plantillas = {}
      snapshot.forEach(doc => {
        plantillas[doc.id] = doc.data()
      });
      setPlantillas({statePlantillas: JSON.parse(JSON.stringify(plantillas)), propsPlantillas: JSON.parse(JSON.stringify(plantillas))});
    })
  }

  const getTaskPlantilla = (idPlantilla) => {
    var tasksRef = db.collection('Servicios/Tareas/tareas');
    tasksRef = tasksRef.where(`plantillas`, "array-contains" , idPlantilla).where(`eliminado`,'==', false).orderBy('position', 'asc')
    tasksRef.onSnapshot(snapshot => {
      var tareas = []
      snapshot.forEach(doc => {
        tareas = [...tareas, doc.data()] 
      });

      const result = {
        ...plantillas.propsPlantillas, 
        [idPlantilla] : {
          ...plantillas.propsPlantillas[idPlantilla],
          tareas
        }
      }
      setEdited(false)
      setPlantillas({statePlantillas: JSON.parse(JSON.stringify(result)), propsPlantillas: JSON.parse(JSON.stringify(result))});
    })

  }

  const openPlantilla = (idPlantilla) => {
    console.log(idPlantilla);
    if(expanded!==idPlantilla){
      setExpanded(idPlantilla)
      getTaskPlantilla(idPlantilla)
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    let oldList = list;
    const [removed] = oldList.splice(startIndex, 1);
    oldList.splice(endIndex, 0, removed);
    let newList = [];
    oldList.map((tarea, indice) => {
      newList.push({...tarea, position: indice})
    })
    return newList;
  };

  const onDragStart = () => {
    console.log('onDragStart()')
    const tabla = document.getElementById('container-clientes-task-list');
    if(tabla?.offsetHeight){
      tabla.style.minHeight = tabla.offsetHeight
    }
  }

  const onDragEnd = (result, plantilla) => {
    const templates = JSON.parse(JSON.stringify(plantillas))
    if (!result.destination) {
      return;
    }

    if (result.destination === result.source.index) {
      return;
    }

    let newTareas = reorder(
      plantilla.tareas,
      result.source.index,
      result.destination.index
    );

    let propsPlantillas = {
      ...templates.propsPlantillas,
      [plantilla.idPlantilla]:{
        ...templates.propsPlantillas[plantilla.idPlantilla],
        tareas: newTareas
     }
    }
    
    setPlantillas({...templates, propsPlantillas})
    
    if(_.isEqual(newTareas,plantillas.statePlantillas[plantilla.idPlantilla].tareas)){
      undoData()
    }else{
      setEdited(true)
    }
  }

  const undoData = () => {
    setPlantillas({...plantillas, propsPlantillas: plantillas.statePlantillas})
    setEdited(false)
  }

 

  return(
    <div id='container-clientes-task' className='container-table min-panel-medios-free pdd_0 overflow_v_hidden' >
      
      <div id='container-clientes-task-list' className="container-plantillas">
        <div className={classes.root}>

          {
            Object.entries(plantillas.propsPlantillas).map(([index, plantilla])=>{
              return(
                <ExpansionPanel id={expanded} key={index} className="item-plantilla-container" expanded={expanded === plantilla.idPlantilla} onChange={handleChange(plantilla.idPlantilla)}>
                  
                  <ItemPlantilla plantilla={plantilla} undoData={undoData} openPlantilla={() => openPlantilla(plantilla.idPlantilla)} getTaskPlantilla={(idPlantilla) => getTaskPlantilla(idPlantilla)} edited={edited && expanded === plantilla.idPlantilla}/> 
                 
                  <ExpansionPanelDetails className="container-plantillas-table">
                    <table id='table-templates-tasks'>
                      <thead>
                        <tr>
                          <th className='tk-clientes-drag'></th>
                          <th className='tk-clientes-title' >
                            <span>Titulo</span>
                          </th>
                          <th className='tk-clientes-prioridad'>
                            <span>Prioridad</span>
                          </th>
                          <th className='tk-clientes-more'></th>
                        </tr>
                      </thead>
                      <DragDropContext onDragEnd={(result) => onDragEnd(result, plantilla)}>
                        <Droppable droppableId="tasks">
                          {(provided, snapshot) => (
                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                              {plantilla.tareas && (
                                plantilla.tareas.map((tarea, index) => {
                                  return(
                                    <ItemTarea key={index} indice={index} tarea={tarea} tarea_seleccionada={tarea_seleccionada} panel="plantilla"/>
                                  )
                                })
                                )
                              }
                            </tbody>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </table>
          
                  </ExpansionPanelDetails>
                  
                  
                </ExpansionPanel>
              )
            })

          }

        </div>

      </div>

      {editTask && plantillas?.propsPlantillas?.[expanded]?.tareas && plantillas.propsPlantillas[expanded].tareas.some(tarea => tarea.id_tarea===tarea_seleccionada) ?
        <EditTask mode='edit' tarea={plantillas.propsPlantillas[expanded].tareas.find(tarea => tarea.id_tarea===tarea_seleccionada)} panel="plantilla"/>: null
      }


    </div>
  )
  
}

function mapStateToProps(state) {
  return {
    empleado: state.empleado,
    editTask: state.clients.task.editTask,
    tarea_seleccionada: state.clients.task.tarea_seleccionada,

  }
}
function matchDispatchToProps(dispatch) { return { actions: bindActionCreators({ setEditTask }, dispatch)} }
export default connect(mapStateToProps, matchDispatchToProps)(Plantillas);