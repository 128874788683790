import React, { Component } from 'react'
import SimpleInput from '../../../Global/SimpleInput'
import * as functions from '../../../Global/functions'
import { connect } from 'react-redux';
import { URLESTADOCLIENTE } from '../../../Global/Data/constants'
import $ from 'jquery'
import { setPanelClientes, setClienteSeleccionado } from '../../../../redux/actions';
import { bindActionCreators } from 'redux';
import SimpleTextArea from '../../../Global/SimpleTextArea'
import firebase from '../../../../firebase/Firebase';
const db = firebase.firestore();


class NuevaPlantilla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: '',
      descripcion: ''
    }
  }

  guardarPlantilla = () => {
    let self = this 
    if(this.state.nombre.trim()===""){
      console.log("No se puede crear sin nombre");
      return false
    }

    var batch = db.batch();
    var idPlantilla = db.collection(`Servicios`).doc('Plantillas').collection('plantillas').doc().id
    var newPlantilla = db.collection(`Servicios`).doc('Plantillas').collection('plantillas').doc(idPlantilla)
    batch.set(newPlantilla,
      {
        idPlantilla,
        status: 'activo',
        nombre: this.state.nombre.trim(),
        descripcion: this.state.descripcion.trim(),
        creado_por: this.props.empleado.id_empleado,

      });

    batch.commit()
    .then(function () {
      console.log('OKAY');
      self.props.close()
    })
    .catch(err=>{
      console.log("err", err)
    })

  }



  render() {
    return (
      <div className='container-opt-search nuevos-enlaces-paid-medios '>
        <div className='opciones-search-popup opciones-search-show-popup pop-up-enlaces-nuevos pop-up-medios-paid-nuevos'>
          <div className='size-medios-popup scroll-bar-exterior nueva-plantilla'>

            <div className="title-pop-up title-center-pop-up">Plantilla nueva</div>

            <SimpleInput title='Nombre' _class_container={this.state.nombre.trim() === '' ? 'error-form-input' : null} text={this.state.nombre} changeValue={nombre => { this.setState({ nombre }) }} />

            <SimpleTextArea title='Descripción' text={this.state.descripcion} changeValue={descripcion => { this.setState({ descripcion }) }}/>

            <div className='btns-finalizar-add-medios-paid'>

              <div className="btn-cancelar-confirm" onClick={(e) => { e.stopPropagation(); this.props.close(); }}>Cancelar</div>
              <div className="btn-aceptar-confirm" onClick={(e) => this.guardarPlantilla(e)}>Guardar</div>

            </div>

          </div>

        </div>


      </div>
    )
  }
}

function mapStateToProps(state) { return { clientes: state.clientes, empleado: state.empleado } }
function matchDispatchToProps(dispatch) { return bindActionCreators({ setPanelClientes, setClienteSeleccionado }, dispatch) }
export default connect(mapStateToProps, matchDispatchToProps)(NuevaPlantilla);
