import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setEmpleadoSeleccionado, setPanelEmpleado, setTareasEmpleado } from '../../../../redux/actions'
import moment from 'moment';
import firebase from '../../../../firebase/Firebase'

const ItemEmpleado = ({empleado, empleado_seleccionado, actions}) => {

  const firestore = firebase.firestore();

  const seleccionarEmpleado = () => {
    if (empleado_seleccionado !== empleado) {
      actions.setEmpleadoSeleccionado(empleado)
      actions.setPanelEmpleado('informacion')
    } else {
      actions.setPanelEmpleado('informacion')
    }
  }

  return (
    <tr data-id={empleado.id_empleado} className={`${empleado_seleccionado && empleado_seleccionado === empleado ? 'active-row-table' : ''}`}>

    <td className='empleados-nombre'>
      <span>{(empleado.nombre ? empleado.nombre : '-') + ' ' + (empleado.apellidos ? empleado.apellidos : '-')}</span>
    </td>

    <td className='empleados-username'>
      <span>{empleado.username ? empleado.username : '-'}</span>
    </td>

    <td className='empleados-email'>
      <span>{empleado.email ? empleado.email : '-'}</span>
    </td>

    <td onClick={() => seleccionarEmpleado()} className='empleados-more'>
      <i className="material-icons align-center">chevron_right</i>
    </td>
   </tr>
  )
}

function mapStateToProps(state) { return {empleado_seleccionado: state.empleado_seleccionado} }
function matchDispatchToProps(dispatch) {return {
  actions: bindActionCreators({
    setEmpleadoSeleccionado,
    setPanelEmpleado,
    setTareasEmpleado
  }, dispatch)}}
export default connect(mapStateToProps, matchDispatchToProps)(ItemEmpleado)