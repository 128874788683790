import React, { useState } from 'react'
import SimpleInput from '../../../Global/SimpleInput'
import * as functions from '../../../Global/functions'
import { connect } from 'react-redux';
import { URLESTADOCLIENTE } from '../../../Global/Data/constants'
import $ from 'jquery'
import { setPanelEmpleado, setEmpleadoSeleccionado } from '../../../../redux/actions';
import { bindActionCreators } from 'redux';
import firebase from '../../../../firebase/Firebase';
import 'firebase/auth';
import Empleado from '../../../../models/Empleado';
const db = firebase.database().ref();

const NuevoEmpleado = ({ close, actions }) => {

  const [nombre, setNombre] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const guardarEmpleado = (e) => {
    let multiPath = {};
    let key = db.child(`Empleados`).push().key

    let empleado = new Empleado({
      nombre: nombre,
      apellidos: apellidos,
      id_empleado: key,
      username: username,
      email: email,
      password: password
    })

    multiPath[`Empleados/${key}`] = empleado
    
    console.log(multiPath);
    db.update(multiPath)
      .then(() => { console.log('Empleado añadido')})
      .catch(err => { console.log(err)})
    
    firebase.auth().createUserWithEmailAndPassword(email, password)
      .catch((err) => console.log(err))

    actions.setEmpleadoSeleccionado(empleado)
    actions.setPanelEmpleado('informacion')
    e.stopPropagation();
    close();
  }

  return (
    <div className='container-opt-search nuevos-enlaces-paid-medios '>
      <div className='opciones-search-popup opciones-search-show-popup pop-up-enlaces-nuevos pop-up-medios-paid-nuevos'>
        <div className='size-medios-popup scroll-bar-exterior nuevo-empleado'>

          <div className="title-pop-up title-center-pop-up">Empleado nuevo</div>

          <SimpleInput title='Nombre' _class_container={nombre.trim() === '' ? 'error-form-input' : null} text={nombre} changeValue={nombre => { setNombre(nombre)}} />
          <SimpleInput title='Apellidos' text={apellidos} changeValue={apellidos => { setApellidos(apellidos)}}/>
          <SimpleInput title='Usuario' text={username} changeValue={username => { setUsername(username)}}/>
          <SimpleInput title='Email' text={email} changeValue={email => { setEmail(email)}}/>
          <SimpleInput title='Password' text={password} changeValue={password => { setPassword(password)}}/>

          <div className='btns-finalizar-add-medios-paid'>
            
            <div className="btn-cancelar-confirm" onClick={(e) => { e.stopPropagation(); close(); }}>Cancelar</div>
            <div className="btn-aceptar-confirm" onClick={(e) => guardarEmpleado(e)}>Guardar</div>
          
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) { return { empleado: state.empleado } }
function matchDispatchToProps(dispatch) { return {actions: bindActionCreators({ setPanelEmpleado, setEmpleadoSeleccionado }, dispatch) }}
export default connect(mapStateToProps, matchDispatchToProps)(NuevoEmpleado);
