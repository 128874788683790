import React, {Component} from 'react'
import ItemsFiltro from '../../../Filtros/ItemsFiltro'
import ListaFiltros from '../../../Filtros/ListaFiltros'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setFiltrosTareasEmpleado, setFechaCalendarioTareas } from '../../../../redux/actions';
import data from '../../../Global/Data/Data';
import Fecha from '../../../Global/Fecha';
import NuevaPlantilla from './NuevaPlantilla';
import NuevoEmpleado from './NuevoEmpleado';


class Filtros extends Component{

  constructor(props){
    super(props)
    this.state={
      show_filtros:false,
      show_new_medios:false,
      fecha: this.props.fecha,
      stringMes:'',
      showNewPlantilla: false,
      showNewEmpleado: false
    }
  }
  componentWillMount = () => {
    this.setFecha()
  }
  componentWillReceiveProps = newProps => {
    if(this.state.fecha!==newProps.fecha){
      this.setState({fecha:newProps.fecha}, ()=>{
        this.setFecha()
      })
    }
    if(this.state.panel!==newProps.panel) {
      this.setState({panel:newProps.panel})
    } 
  }
  setFecha = () => {
    var array = this.state.fecha.split('-');
    var mes = (+array[1]);
    var stringMes = data.months[mes - 1]
    this.setState({ stringMes })
  }

  addNewTemplate = () => {

  }

  render(){
    return(
      <div className='pr'>
        <ItemsFiltro filtros={this.props.filtros} updateFiltros={(filtros=>this.props.setFiltrosTareasEmpleado(filtros))}/>
        <div className='opciones-alumnos'>
          <div className='deg-opt'></div>
          
          {/*MOSTRAR SI SOLO ES EL PANEL DE CALENDARIO*/}
          {this.props.panel==="calendario" && (
            <div className='btn-options pr'>
              <i className="material-icons"> calendar_today </i> <span>{this.state.stringMes}</span>
              <Fecha setFecha={fecha => this.props.setFechaCalendarioTareas(fecha)} clss={'input-fecha-enlaces'} id={'date-enlaces-free'} position={'fecha_enlaces_position'} month={this.state.fecha.split('-')[1]} year={this.state.fecha.split('-')[0]} />
            </div>
          )}
          

          <div className='btn-options pr' onClick={()=>this.setState({show_filtros:this.state.show_filtros?false:true})}>

            <i className="material-icons"> filter_list </i> <span>Filtros</span>
            {this.state.show_filtros?
                <ListaFiltros filtros={this.props.filtros} updateFiltros={(filtros=>this.props.setFiltrosTareasEmpleado(filtros))} close={()=>this.setState({show_filtros:false})}/>:null
            }
          </div>

          {/*Items barra*/}
          
          {this.props.panel === 'plantillas' ?
          <div className={`item-container-icon-top-bar pr ${this.state.showNewPlantilla ? 'color-azul' : ''}`} >
            <i onClick={() => this.setState({showNewPlantilla: true})} className="material-icons hover-azul middle-item">add</i>

            {this.state.showNewPlantilla ?
              <NuevaPlantilla close={() => { this.setState({ showNewPlantilla: false }) }} /> : null
            }

          </div> : null
          }

          {this.props.panel === 'listado' ?
          <div className={`item-container-icon-top-bar pr ${this.state.showNewPlantilla ? 'color-azul' : ''}`} >
            <i onClick={() => this.setState({showNewEmpleado: true})} className="material-icons hover-azul middle-item">add</i>

            {this.state.showNewEmpleado ?
              <NuevoEmpleado close={() => { this.setState({ showNewEmpleado: false }) }} /> : null
            }

          </div> : null
          } 

        </div>

      </div>
    )
  }

}

function mapStateToProps(state){return{   filtros:state.panelEmpleado.paneles.tareas.filtros, fecha: state.panelEmpleado.fechaCalendario, panel: state.panelEmpleado.panel }}
function  matchDispatchToProps(dispatch){ return bindActionCreators({ setFiltrosTareasEmpleado, setFechaCalendarioTareas }, dispatch) }

export default connect(mapStateToProps, matchDispatchToProps)(Filtros);
