import React, { useEffect, useState } from 'react'
import firebase from '../../firebase/Firebase';
import Firebase from 'firebase'

const db = firebase.database().ref();

const Prueba = () => {

  const [medios, setMedio] = useState({})
  const [mediosUsados, setMediosUsados] = useState({})



  useEffect(()=>{
    //updateEliminadoMedios()
    //eliminarClientes()
    //eliminarServicios()
  }, [])
  const eliminarServicios = () => {
    let multiPath = {};
    multiPath[`Servicios/Logs`]=null
    multiPath[`Servicios/Tareas`]=null
    multiPath[`Servicios/Times`]=null
    multiPath[`Servicios/Tracking`]=null
    multiPath[`Servicios/Linkbuilding/Free/Enlaces`]=null
    multiPath[`Servicios/Linkbuilding/Paid/Enlaces`]=null
    db.update(multiPath)
    .then(()=>{
      console.log("ok");
      
    })
    .catch(err=>{
      console.log(err);
      
    })
  }
  const eliminarOthers = () => {
    let multiPath = {};
    multiPath[`Others`]=null
    db.update(multiPath)
  }

  const eliminarClientes = () => {
    db.child('Clientes').once("value", snapshot => {
      var clientes = {}, multiPath = {};
      snapshot.forEach(data => {
        clientes[data.key] = data.val();
        if(data.val().web!=="http://prueba.com")
          multiPath[`Clientes/${data.key}`]=null
      });
      db.update(multiPath)
    })
  }

  const borrarMediosSinUso = () => {
    db.child('Servicios/Linkbuilding/Free/Medios/categorias').on("value", snapshot => {
      var medios = {}, multiPath = {};
      snapshot.forEach(data => {
        medios[data.key] = data.val();
        medios[data.key].valor = data.val().nombre;//Este nuevo atributo nos sirve para ver el desplegable de categorias en cada enlace gratuito que se haga
      });
      setMedio(medios)
      console.log('medios')
    })
    db.child('Clientes').on("value", snapshot => {

      var clientes = {}, multiPath = {}, notificaciones = [], mediosUsados = {}
      snapshot.forEach(data => {
        var cliente = data.val()
        clientes[data.key] = cliente;

        if(cliente.servicios.linkbuilding.free.home.medios_usados_follow){
          Object.entries(cliente.servicios.linkbuilding.free.home.medios_usados_follow).forEach(([key, medio])=>{
            if(!mediosUsados[medio.categoria]) mediosUsados[medio.categoria] = {}
            mediosUsados = { 
              ... mediosUsados, 
              [medio.categoria] : {
                ...mediosUsados[medio.categoria],
                [medio.id_medio]: true
              }
            }
          })
        }
      
      })
      setMediosUsados(mediosUsados)
      console.log('medios usados')

    })
  }
  const deleteMedios = () => {
    db.child('Servicios/Linkbuilding/Free/Medios/categorias').on("value", snapshot => {
      var medios = {}, multiPath = {};
      snapshot.forEach(data => {
        const categoria = data.val();
        if(!categoria.medios) return false
        
        Object.entries(categoria.medios).forEach(([k, m]) => {
          multiPath[`Servicios/Linkbuilding/Free/Medios/categorias/${data.key}/medios/${k}/`] = null
        })
      });
      console.log(multiPath);
      /*db.update(multiPath)
      .then(()=>{
        console.log("ok");
      })
      .catch(err=>{
        console.log("err", err)
      })*/
      
    })

  }

  const deleteMediosSinUsar = () => {
    console.log('MEDIOS', medios);
    console.log('MEDIOS USADOS', mediosUsados);

    let medioEliminar = {}
    
    Object.entries(medios).forEach(([i,categoria])=>{
      Object.entries(categoria.medios).forEach(([j, medio])=>{
        
        if(!mediosUsados[categoria.id][medio.id_medio] && medio.eliminado){
          if(!medioEliminar[categoria.id])medioEliminar[categoria.id]={}
          medioEliminar[categoria.id][medio.id_medio] = true
        }
        
      })
    })

    console.log("MEDIOS A ELIMINAR", medioEliminar);
    let multipath = {}
    Object.entries(medioEliminar).forEach(([idCategoria, categoria])=>{
      Object.entries(categoria).forEach(([idMedio, medio])=>{
        multipath[`Servicios/Linkbuilding/Free/Medios/categorias/${idCategoria}/medios/${idMedio}`] = null
      })
    })

    console.log('====================================');
    console.log(multipath);
    console.log('====================================');
    db.update(multipath)
    .then(()=>{
      console.log('====================================');
      console.log("ok");
      console.log('====================================');
    })

  }

  const updateEliminadoMedios = () => {
    db.child('Servicios/Linkbuilding/Free/Medios/categorias').on("value", snapshot => {
      var medios = {}, multiPath = {};
      snapshot.forEach(data => {
        const categoria = data.val();
        if(!categoria.medios) return false
        Object.entries(categoria.medios).forEach(([k, m]) => {
          multiPath[`Servicios/Linkbuilding/Free/Medios/categorias/${data.key}/medios/${k}/activo`] = false
          multiPath[`Servicios/Linkbuilding/Free/Medios/categorias/${data.key}/medios/${k}/eliminado`] = true
        })
      });
      console.log(multiPath);
      /*db.update(multiPath)
      .then(()=>{
        console.log("ok");
      })
      .catch(err=>{
        console.log("err", err)
      })*/
      
    })
  }

  return(
    <div>
      <button onClick={()=>deleteMedios()}>BORRAR</button>
    </div>
  )
}

export default Prueba