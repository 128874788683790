import React, {useState} from  'react'
import { ExpansionPanelDetails, ExpansionPanelSummary, Menu, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setEditTask, setTareaSeleccionada } from '../../../../redux/actions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MoreVertRounded } from '@material-ui/icons';
import moment from 'moment'
import firebase from '../../../../firebase/Firebase';
import data from '../../../Global/Data/Data';
import SimpleInputDesplegableMin from '../../../Global/SimpleInputDesplegableMin'
import ConfirmAlert from '../../../Global/ConfirmAlert'
import UpdateStateInputs from '../../../Global/UpdateStateInputs'
 

const db = firebase.firestore();

const ItemPlantilla = ({plantilla, empleado, actions, openPlantilla, getTaskPlantilla, edited, undoData}) => {

  const [showAlertDelete, setShowAlertDelete] = useState(false)

  const changeValor = (valor) => {
    if(valor.trim().toLowerCase()==="crear tarea"){
      newTask()
    }else if(valor.trim().toLowerCase() === "eliminar plantilla"){
      setShowAlertDelete(true)
    }
  }

  const newTask = () => {

    var batch = db.batch();
    var id_tarea = db.collection(`Servicios`).doc('Tareas').collection('tareas').doc().id
    var newTask = db.collection(`Servicios`).doc('Tareas').collection('tareas').doc(id_tarea)

    batch.set(newTask,
      {
        id_tarea,
        estado: 'no_completado',//No completado, en proceso, completado
        completado:false,
        eliminado: false,
        title: 'Tarea nueva',
        descripcion: '',
        fecha_limite: moment().format("YYYY-MM-DD"),
        prioridad: 2,
        en_proceso:{
          type:"simple"
        },
        tags: [],
        empleados: {},
        id_cliente: "plantilla",
        fecha_creacion: (+ new Date()),
        repetir: 'nunca',
        intervalo: { repeat: 'nunca', valor: null, valorExtra: null },
        creado_por: empleado.id_empleado,
        plantillas: [plantilla.idPlantilla]
        //tarea_padre,este atributo existirá cuando se reptita la tarea

      });

    batch.commit()
    .then(() => {
      console.log('OKAY');
      openPlantilla()
      actions.setEditTask(true);
      actions.setTareaSeleccionada(id_tarea)
      
    })
    .catch(err=>{
    })  
  
  }

  const deletePlantilla = () => {

    var batch = db.batch();
    var refPlantilla = db.collection(`Servicios`).doc('Plantillas').collection('plantillas').doc(plantilla.idPlantilla)

    batch.update(refPlantilla, {status: "eliminado"})
    batch.commit()
    .then(() => {
      console.log('OKAY');
    })
    .catch(err=>{
    })  
  
  }

  const saveData = (idPlantilla) => {
    let batch = db.batch();
    plantilla.tareas.map((tarea, indice) => {
      let tasksRef = db.collection('Servicios').doc('Tareas').collection('tareas').doc(tarea.id_tarea);
      batch.update(tasksRef, { position: tarea.position})
      console.log(tasksRef, tarea.position)
    })
   batch.commit()
    .then(() => {
      console.log('Actualizado correctamente')
      getTaskPlantilla(idPlantilla)
    })
  }

  const confirmDelete = (valor) => {
    console.log(valor)
    if(valor==="confirmar"){
      deletePlantilla()
    }
    setShowAlertDelete(false)
  }

  return(
    <ExpansionPanelSummary  expandIcon={<ExpandMoreIcon />}  >
      <div className={"title-plantilla"}>{plantilla.nombre}</div>
      <div className={"description-plantilla"}>{plantilla.descripcion}</div>

      {edited ? <UpdateStateInputs saveData={() => saveData(plantilla.idPlantilla)} undoData={() => undoData()} /> : null}
      <div onClick={e=>newTask(e, plantilla.idPlantilla)} className="container-more-plantillas" > 
        <div> <MoreVertRounded /></div>
        <SimpleInputDesplegableMin _class_input={"options-plantilla-more"} text={"more"} lista={data.optionsMorePlantilla}  changeValor={valor => changeValor(valor)} />
      </div>

      {showAlertDelete && 
        <ConfirmAlert text_1={"¿Estas seguro que desear eliminar la plantilla "} bold_1={plantilla.nombre} text_2={" ?"} cancelar={"Cancelar"} confirmar={"Aceptar"} confirmResult={(valor)=>confirmDelete(valor)}/>
      }
    </ExpansionPanelSummary>
  )
}

function mapStateToProps(state) {
  return {
    empleado: state.empleado,
    editTask: state.clients.task.editTask,
    tarea_seleccionada: state.clients.task.tarea_seleccionada,

  }
}
function matchDispatchToProps(dispatch) { return { 
  actions: bindActionCreators({ 
    setTareaSeleccionada,
    setEditTask
  }, dispatch)} 
}
export default connect(mapStateToProps, matchDispatchToProps)(ItemPlantilla);