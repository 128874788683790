import React from 'react'
import { connect } from 'react-redux';
import ItemEmpleado from './ItemEmpleado';

const ListaEmpleados = ({ empleados }) => {

  return (
    <div id='container-clientes' className='container-table min-panel-medios-free'>
      
        <div>
          <table id='table-empleados'>
            <thead>
              <tr>
                <th className='empleados-nombre'>
                  <span>Nombre</span>
                </th>

                <th className='empleados-username'>
                  <span>Username</span>
                </th>

                <th className='empleados-email'>
                  <span>Email
                  </span>
                </th>

                <th className='empleados-more'></th>
              </tr>
            </thead>
            <tbody>
              { empleados != null ?
              Object.entries(empleados).map((empleado, indice) => {
                return (
                  <ItemEmpleado key={indice} empleado={empleado[1]}/>
                )}) : null
              }
            </tbody>
          </table>
        </div>

  </div>
  )
}

function mapStateToProps(state) { return {empleados: state.empleados} }
export default connect(mapStateToProps, null)(ListaEmpleados);