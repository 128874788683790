import React, {Component, PureComponent} from 'react'
import {cleanProtocolo, isLink} from '../../../../../Global/functions'
import moment from 'moment'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setVistaLinkBuilding, setClienteSeleccionado, setPanelClientesFreeLinkbuilding, setPopUpInfo } from '../../../../../../redux/actions';
import data from '../../../../../Global/Data/Data'
import _ from 'underscore';

import firebase from '../../../../../../firebase/Firebase';
import Checkbox from '../../../../../Global/CheckBox';
import {StarRounded, StarBorderRounded} from '@material-ui/icons'

const db = firebase.firestore();

class SideBar extends Component{

  constructor(props){
    super(props)
    this.state={

      plantillas:{},
      tasks:{},
      blockPanel: false

    }
  }

  componentWillReceiveProps = (newProps) => {
    if(this.state.estrategia!==newProps.estrategia){
      this.setState({estrategia: newProps.estrategia})
    }
  }

  componentDidMount = () => {
    setTimeout(() => { this.setState({visible:true}) }, 0)
  }

  close = () => {
    this.setState({visible:false})
    setTimeout(() => { this.props.callBack() }, 500)
  }

  componentWillMount = () => {
    this.getData()
  }

  getData = async () => {

    let self = this

    const mergePlantillasTask = (tasks = this.state.tasks , plantillas = this.state.plantillas) => {
      Object.entries(tasks).forEach(([key, task])=>{
        task.plantillas.forEach( plantilla => {
          if(plantillas[plantilla]){
            if(!plantillas[plantilla].tasks) plantillas[plantilla].tasks = {}
            if(!plantillas[plantilla].tasks[task.id_tarea])
              plantillas[plantilla].tasks = { ...plantillas[plantilla].tasks, [task.id_tarea]: task}
          }
        })
      })
      console.log("plantillas", plantillas)
      this.setState({plantillas})
    }
    
    const getPlantillas = () => {
      return new Promise( (resolve, reject)=>{
        var plantillasRef = db.collection('Servicios/Plantillas/plantillas');
        plantillasRef = plantillasRef.where(`status`, "==" , "activo")
        plantillasRef.onSnapshot(snapshot => {
          var plantillas = {}
          snapshot.forEach(doc => {
            plantillas[doc.id] = doc.data()
          });
          this.setState({plantillas})
          console.log(self.task);
          
          mergePlantillasTask(self.task, plantillas)
          return resolve(plantillas)
        })
      })
    }

    const getTaskPlantillas = () => {
      return new Promise( (resolve, reject)=>{
        var taskRef = db.collection('Servicios/Tareas/tareas');
        taskRef = taskRef.where(`id_cliente`, "==" , "plantilla")
        taskRef.onSnapshot(snapshot => {
          var task = {}
          snapshot.forEach(doc => {
            task[doc.id] = doc.data()
          });
          this.setState({task})
          console.log(self.plantillas);

          mergePlantillasTask(task, self.plantillas)
          return resolve(task)
        })
      })
    }

    let plantillas = await getPlantillas()
    let tasks = await getTaskPlantillas()
    mergePlantillasTask(tasks, plantillas)
  }

 changeChecked = (value, idTask, idPlantilla) => {
   let plantillas = {
     ...this.state.plantillas,
     [idPlantilla]:{
      ...this.state.plantillas[idPlantilla],
      tasks: {
        ...this.state.plantillas[idPlantilla].tasks,
        [idTask]: {
          ...this.state.plantillas[idPlantilla].tasks[idTask],
          checked: value
        } 
      }
     }
    }

    if(Object.entries(plantillas[idPlantilla].tasks).some(([key, task]) => !task.checked)){
      plantillas[idPlantilla].checked = false
    }else{
      plantillas[idPlantilla].checked = true

    }
    
   this.setState({plantillas})
 }

 changeCheckBoxPlantilla = (value, idPlantilla) => {
  
  if( this.state.plantillas[idPlantilla].tasks ){
    let plantillas = {
      ...this.state.plantillas,
      [idPlantilla]: {
        ...this.state.plantillas[idPlantilla],
        checked: value
      }
    }

    Object.entries(plantillas[idPlantilla].tasks).forEach(([key, task])=>{
      plantillas[idPlantilla].tasks[key].checked = value
    })

    this.setState({plantillas})
  }
 }

 saveTasks = () => {
    this.setState({blockPanel:true}, ()=>{

      let tasks = {}
      Object.entries(this.state.plantillas).forEach(([i, plantilla])=>{
        if(plantilla.tasks){
          Object.entries(plantilla.tasks).forEach(([j, task])=>{
            if(task.checked){
              tasks[j] = task 
            }
          })
        }
      })
  
      //RECORRER TODAS LAS TASKS Y LAS INSERTAMOS EN EL CLIENTE
      //Cambiar la fecha fin por dentro de 1 semana
      //cambiar el id_client
      //eliminar el atributo plantillas
      //crear un id de tarea para que no se repita el que ya existep
      var batch = db.batch();
      Object.entries(tasks).forEach(([id, task])=>{
  
        const idTarea = db.collection(`Servicios`).doc('Tareas').collection('tareas').doc().id
        var refTask = db.collection(`Servicios`).doc('Tareas').collection('tareas').doc(idTarea)
  
        let tarea = {
          ...task,
          id_tarea: idTarea,
          plantillas: null,
          fecha_creacion: +new Date(),
          fecha_limite: moment().add(7, 'days').format('YYYY-MM-DD',),
          id_cliente: this.props.cliente_seleccionado.id_cliente,
          creado_por: this.props.empleado.id_empleado
        }
        console.log("tarea", tarea);
        batch.set(refTask, tarea)
      })
  
      batch.commit()
      .then(() => {
        this.setState({blockPanel:false})
        this.props.setPopUpInfo({ visibility: true, status: 'done', moment: Date.now(), text: 'Se han creado correctamente' })
        this.close()
      })
      .catch(err=>{
        console.log(err);
        this.setState({blockPanel:false})
        this.props.setPopUpInfo({ visibility: true, status: 'close', moment: Date.now(), text: 'No se pueden crear las tareas seleccionadas' })

      })

    })
    
 }



  render(){

    return(
      <div id='select-plantillas-task' className='container-block-side' onClick={()=>{this.close()}}>

        {this.state.blockPanel && <div className='block-panel-new-tarea'></div>}

        <div className={`container-side ${this.state.visible?'container-side-activo':''}`} onClick={(e)=>e.stopPropagation(e)}>
          <div className='title-side-bar-medios pr'>

            <div className='close-side-bar-medios'><i className="material-icons " onClick={()=>this.close()}> cancel </i></div>

            <div className='text-title-medios'>Plantillas</div>
            
            <div className='subtext-title-medios'>{this.props.subtext}</div>
            <div className='container-opciones-medios'>
              <div className={`opciones-bar-medios width_100 opc-activa txt-title-url-estrategia`}>
                <span className="pr add-new-destino-estrategia">
                  Agregar tareas
                </span>
              </div>
            </div>

          </div>
          <div className='scroll-side scroll-bar-medios pr' >
            
            {
              Object.keys(this.state.plantillas).length > 0 ? Object.entries(this.state.plantillas).map(([k,plantilla])=>{
                return(
                  <Plantilla 
                    plantilla={plantilla} 
                    checked={plantilla.checked ? plantilla.checked:false}
                    changeCheckBoxPlantilla={(value)=>{this.changeCheckBoxPlantilla(value, k)}}
                    changeChecked={(value, idTask)=>{this.changeChecked(value, idTask, k)}} 
                  />
                )
              }):null
            }



          </div>
          <div className="bottom-bar-options">
              <div className="cancel-btn" onClick={()=>{this.close()}}>Cancelar</div>
              <div className="ok-btn" onClick={()=>this.saveTasks()}>Aceptar</div>
            </div>  
        


        </div>
      </div>

    )
  }

}

function mapStateToProps(state){return{ empleado : state.empleado, cliente_seleccionado: state.cliente_seleccionado }}
function matchDispatchToProps(dispatch) { return bindActionCreators({ setVistaLinkBuilding, setClienteSeleccionado, setPanelClientesFreeLinkbuilding, setPopUpInfo }, dispatch) }
export default connect(mapStateToProps, matchDispatchToProps)(SideBar);

class Plantilla extends Component{

  constructor(props){
    super(props)
    this.state={
      showTasks: false
    }
  }

  render(){
    
    return(
      <div  className='medios-cliente-disponible pr'>
        <div className='info-cli-dispo'>
          
          <Checkbox _class='checkbox-anchors-container' _class_item='checkbox-anchors' checked={this.props.checked} changeValue={(value)=>this.props.changeCheckBoxPlantilla(value)}/>

          <div className='arrow-container-sidebar' onClick={()=>this.setState({showTasks:!this.state.showTasks})}>
            <i className={`material-icons ${this.state.showTasks?'open-arrow-sidebar':''}`} > arrow_right </i>
          </div>
          
          <div className="pr edit-url-container-estrategia" onClick={()=>this.setState({showTasks:!this.state.showTasks})}>
            <input className={`item-keyword-estrategia-input`} value={this.props.plantilla.nombre} readOnly={true}/>
          </div>

        </div>
        {this.state.showTasks && this.props.plantilla.tasks && Object.keys(this.props.plantilla.tasks).length > 0 &&
          <div className='vista-fechas'>
            <ul>
              {Object.entries(this.props.plantilla.tasks).map(([i, task])=>{
                return(
                  <ItemTask key={i} task={task} 
                    checked={task.checked ? task.checked:false}
                    changeCheckBox={(value)=>this.props.changeChecked(value, i)}
                  />
                )
              })
              }
            </ul>
          </div>
        }

               

      </div>
      
    )
  }
}

class ItemTask extends Component {

  shouldComponentUpdate = (nextProps) => {
    if(this.props.task!==nextProps.task){
      return true
    }
    if(this.props.checked!==nextProps.checked){
      return true
    }
    return false
  }

  render(){
    console.log('render task');
    
    return(
      <div className="container-keyword">
        <Checkbox _class='checkbox-anchors-container' _class_item='checkbox-anchors' checked={this.props.checked} changeValue={(value)=>this.props.changeCheckBox(value)}/>
        <div className="container-item-keyword">
            <input className={"item-keyword-estrategia-input"} value={this.props.task.title} readOnly={true}/>
        </div>
      </div>
    )
  }

}

